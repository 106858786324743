<template>
    <div  @click.stop  :style="{ '--width': width}" :class="isTop?'tree-source-panel-l':'tree-source-panel'" @scroll="onScroll">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TreePanel",
    props: {
        width: {
            default: "250px",
            type: String,
        },
        // 距离组件底部多远触发加载
        distance: {
            default: 20,
            type: Number
        },
        isTop:{
            default:false,
            type:Boolean
        }
    },
    data() {
        return {
            timer: null,
        }
    },
    methods: {
        onScroll(e) {
            const {scrollTop, clientHeight, scrollHeight } = e.target;
            if (scrollTop !== 0 && scrollHeight - scrollTop - clientHeight  < this.distance + scrollHeight % clientHeight) {
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setTimeout(() => {
                    this.$emit('loadMore')
                }, 500);
            }
        }
    }
};
</script>


<style lang="stylus" scoped>
.tree-source-panel
    min-width var(--width, 250px)
    height 320px
    overflow-y auto
    background #FFFFFF
    z-index 2
    padding 10px
    box-shadow 0 0 10px 0 rgba(0, 0, 0, 0.15)
    border-radius 10px
    position absolute
    left 0
    top calc(var(--height, 40px) + 10px)
.tree-source-panel-l
    min-width var(--width, 250px)
    height 320px
    overflow-y auto
    background #FFFFFF
    z-index 2
    padding 10px
    box-shadow 0 0 10px 0 rgba(0, 0, 0, 0.15)
    border-radius 10px
    position absolute
    left 0
    bottom  calc(var(--height, 40px) + 10px) !important
</style>
