
<template>
    <div v-if="paginationInfo.total > paginationInfo.pageSize" style="position: relative">
        <nav class="page-navigation " aria-label="Page navigation example">
            <ul class="pagination justify-content-end">
                <template v-if="paginationInfo.isShowPageSelector && paginationInfo.pageTotal > 0">
                    <li
                        class="page-item"
                        v-bind:class="{
                            disabled: paginationInfo.currentPage == 1,
                        }"
                    >
                        <a class="page-link" v-on:click="current(1)"> « </a>
                    </li>
                </template>
                <li
                    class="page-item"
                    v-bind:class="{ disabled: paginationInfo.currentPage == 1 }"
                >
                    <a class="page-link" v-on:click="previous()"> ‹ </a>
                </li>
                <template v-if="paginationInfo.isShowPageSelector && paginationInfo.total > 0">
                    <li
                        class="page-item"
                        v-for="(pageInfo, index) in paginationInfo.pageList"
                        v-bind:class="{ active: pageInfo.currentPage }"
                        :key="index"
                    >
                        <a
                            class="page-link"
                            v-on:click="current(pageInfo.page)"
                            >{{ pageInfo.pageView }}</a
                        >
                    </li>
                </template>
                <li
                    class="page-item"
                    v-bind:class="{
                        disabled:
                            paginationInfo.pageTotal > 0 &&
                            paginationInfo.currentPage == paginationInfo.pageTotal,
                    }"
                >
                    <a class="page-link" v-on:click="next()"> › </a>
                </li>
                <template v-if="paginationInfo.isShowPageSelector && paginationInfo.total > 0">
                    <li
                        class="page-item"
                        v-bind:class="{
                            disabled:
                                paginationInfo.currentPage ==
                                paginationInfo.total,
                        }"
                    >
                        <a
                            class="page-link"
                            v-on:click="current(paginationInfo.pageTotal)"
                        >
                            »
                        </a>
                    </li>
<!--                    <span class="total" style="line-height: 30px; margin-left: 10px" v-if="paginationInfo.total != 0">
                        共 {{ paginationInfo.total }} 条
                    </span>-->
                </template>
            </ul>
            <div>
                <span>跳至</span>
                <div class="input-wrap">
                    <input type="text" v-model.number="inputPage" >
                    <span>页</span>
                </div>
                <button @click="current(inputPage)" class="btn btn-primary">跳转</button>
            </div>
        </nav>
    </div>
</template>
<script>


export default {
    name: "Pagination",
    props: {
        tabNameOrId: {
            type: [Number, String],
            default: '',
        }
    },
    components: {},
    data() {
        return {
            inputPage: 1, // 输入的page页码
            paginationInfo: {
                pageTotal: 0,
                total: 0,
                currentPage: 1,
                pageList: [],
                pageSize: 10,
                isShowPageSelector: true,
            },
        };
    },
    watch: {
      'paginationInfo.currentPage': function(val) {
          this.inputPage = val;
      }
    },
    mounted() {
        this.$vc.on(this.$route.path + this.tabNameOrId, "pagination_info", "event", this.paginationHandler);

        this.$vc.on(this.$route.path + this.tabNameOrId, "pagination", "init", this.paginationHandler);
    },
    methods: {
        paginationHandler(_paginationInfo) {
            console.log('初始化服务', _paginationInfo);
            for (let key in _paginationInfo) {
                if (this.paginationInfo.hasOwnProperty(key)) {
                    this.paginationInfo[key] = _paginationInfo[key];
                }
            }
            if (_paginationInfo.total) {
                this.paginationInfo.pageTotal = Math.ceil(_paginationInfo.total / _paginationInfo.pageSize) || 0;
            }
            if(this.paginationInfo.pageTotal) {
                this._freshPageList();
            }
        },
        previous: function () {
            // 当前页为 1时 不触发消息
            if (this.paginationInfo.currentPage <= 1) {
                return;
            }
            this.paginationInfo.currentPage =
                this.paginationInfo.currentPage - 1;
            this.$vc.emit(this.$route.path + this.tabNameOrId,
                "pagination_page",
                "event",
                this.paginationInfo.currentPage
            );
            if(this.paginationInfo.pageTotal) {
                this._freshPageList();
            }
        },
        next: function () {
            if (
                this.paginationInfo.isShowPageSelector &&
                this.paginationInfo.currentPage >= this.paginationInfo.pageTotal
            ) {
                return;
            }
            this.paginationInfo.currentPage =
                this.paginationInfo.currentPage + 1;
            this.$vc.emit(this.$route.path + this.tabNameOrId,
                "pagination_page",
                "event",
                this.paginationInfo.currentPage
            );
            if(this.paginationInfo.pageTotal) {
                this._freshPageList();
            }
        },
        current: function (_page) {
            if (_page == -1) {
                return;
            }
            if (_page > this.paginationInfo.pageTotal) {
                return;
            }
            this.paginationInfo.currentPage = _page;

            this.$vc.emit(this.$route.path + this.tabNameOrId,
                "pagination_page",
                "event",
                this.paginationInfo.currentPage
            );
            if(this.paginationInfo.pageTotal) {
                this._freshPageList();
            }
        },
        _freshPageList: function () {
            var current = this.paginationInfo.currentPage;
            var total = this.paginationInfo.pageTotal;
            this.paginationInfo.pageList = [];
            if (total > 6) {
                //当前页数小于5时显示省略号
                if (current < 5) {
                    for (var i = 1; i < 6; i++) {
                        if (current == i) {
                            this.paginationInfo.pageList.push({
                                page: i,
                                pageView: i + "",
                                currentPage: true,
                            });
                        } else {
                            this.paginationInfo.pageList.push({
                                page: i,
                                pageView: i + "",
                                currentPage: false,
                            });
                        }
                    }
                    this.paginationInfo.pageList.push({
                        page: -1,
                        pageView: "...",
                        currentPage: false,
                    });
                    this.paginationInfo.pageList.push({
                        page: total,
                        pageView: total + "",
                        currentPage: false,
                    });
                } else {
                    //判断页码在末尾的时候
                    if (current < total - 3) {
                        for (var i = current - 2; i < current + 3; i++) {
                            if (current == i) {
                                this.paginationInfo.pageList.push({
                                    page: i,
                                    pageView: i + "",
                                    currentPage: true,
                                });
                            } else {
                                this.paginationInfo.pageList.push({
                                    page: i,
                                    pageView: i + "",
                                    currentPage: false,
                                });
                            }
                        }
                        this.paginationInfo.pageList.push({
                            page: -1,
                            pageView: "...",
                            currentPage: false,
                        });
                        this.paginationInfo.pageList.push({
                            page: total,
                            pageView: total + "",
                            currentPage: false,
                        });
                        //页码在中间部分时候
                    } else {
                        this.paginationInfo.pageList.push({
                            page: 1,
                            pageView: 1 + "",
                            currentPage: false,
                        });
                        this.paginationInfo.pageList.push({
                            page: -1,
                            pageView: "...",
                            currentPage: false,
                        });
                        for (var i = total - 4; i < total + 1; i++) {
                            if (current == i) {
                                this.paginationInfo.pageList.push({
                                    page: i,
                                    pageView: i + "",
                                    currentPage: true,
                                });
                            } else {
                                this.paginationInfo.pageList.push({
                                    page: i,
                                    pageView: i + "",
                                    currentPage: false,
                                });
                            }
                        }
                    }
                }
                //页面总数小于6的时候
            } else {
                for (var i = 1; i < total + 1; i++) {
                    if (current == i) {
                        this.paginationInfo.pageList.push({
                            page: i,
                            pageView: i + "",
                            currentPage: true,
                        });
                    } else {
                        this.paginationInfo.pageList.push({
                            page: i,
                            pageView: i + "",
                            currentPage: false,
                        });
                    }
                }
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
.page-navigation
    text-align right
    padding 0 30px
    color #000
    ul
        display inline-flex
        vertical-align middle
        margin-bottom 0
        li
            a
                border 1px solid #999
                min-width 30px
                height 30px
                line-height 20px
            &.disabled a
                    border 1px solid #999
    & > div
        display inline-block
        vertical-align middle
        margin-left 10px
        span
            display inline-block
            vertical-align middle
        .input-wrap
            border 1px solid #999
            display inline-block
            vertical-align middle
            border-radius .25rem
            height 30px
            width 74px
            box-sizing border-box
            text-align right
            font-size 0
            margin-left 10px
            input
                border unset
                outline none
                width 44px
                vertical-align middle
                text-align right
                font-size 14px
                line-height 28px
                height 100%
            span
                font-size 14px
                height 100%
                padding 0 6px
                line-height 28px
        .btn
            margin-left 20px
            height 30px
            line-height 30px
            padding 0 12px
</style>
