<template>
    <div class='CSImage-container' :style="{width:width,height:height} ">
        <img class="bg1" :src="srcImg">
        <div  class="enlargeBtnClass">

            <svg
                    class="icon iconSize"
                    aria-hidden="true"
                    @click="enlargeBtn"
                    style="opacity: 1"
            >
                <use
                        :xlink:href="
              '#icon-menua-jianqu1'
            "
                ></use>
            </svg>

        </div>
        <div class="bgContainer" v-if="isShow" ref="bgContainer">
            <img :src="srcImg" class="bg2">
            <div @click="deleteBtn" class="btnsd">X</div>
        </div>

    </div>
</template>

<script>

    export default {
        name: "CSImage",
        props: {
            srcImg: {
                type: String,
                default: ''
            },
            width: {
                type: String,
                default: '100%'
            },
            height: {
                type: String,
                default: 'auto'
            }
        },
        data() {
            return {
                isShow: false,
                bgContainerDom: null,
                bg2Dom: null,
                disx: 0,
                disy: 0
            }
        },
        components: {},
        mounted() {

        },
        methods: {
            //关闭图片
            deleteBtn() {
                this.isShow = false;
                window.removeEventListener("wheel", this.whellBtn);
            },
            enlargeBtn() {
                this.isShow = true;
                this.$nextTick(() => {
                    this.bgContainerDom = document.getElementsByClassName('bgContainer')[0];
                    this.bg2Dom = document.getElementsByClassName('bg2')[0];
                    this.addEvent();
                })
            },
            //天添加事件
            addEvent() {
                this.bgContainerDom.removeEventListener('mousedown', this.moveEvent);
                this.bgContainerDom.removeEventListener('mouseup', this.moveEventUp);
                window.removeEventListener("wheel", this.whellBtn)
                //鼠标按下
                this.bgContainerDom.addEventListener('mousedown', this.moveEvent);
                //鼠标抬起
                this.bgContainerDom.addEventListener('mouseup', this.moveEventUp);

                window.addEventListener("wheel", this.whellBtn)

            },
            //鼠标滚轮事件
            whellBtn(e) {
                let clientWidth = this.bg2Dom.clientWidth;
                let clientHeight = this.bg2Dom.clientHeight;

                let speedY = clientHeight / clientWidth;
                let speedX = clientHeight / clientWidth
                let evt = e || window.event;  //考虑兼容性

                if ((parseInt(clientWidth) < 200 || parseInt(clientHeight) < 200) && evt.deltaY > 0) {
                    return
                }
                if ((parseInt(clientWidth) > 3000 || parseInt(clientHeight) > 3000) && evt.deltaY < 0) {
                    return
                }
                this.bg2Dom.style.width = parseInt(clientWidth) - evt.deltaY * speedX + 'px';
                this.bg2Dom.style.height = parseInt(clientHeight) - evt.deltaY * speedY + 'px';
            },
            //鼠标事件
            moveEvent(event) {
                this.disx = event.clientX - this.bg2Dom.offsetLeft;
                this.disy = event.clientY - this.bg2Dom.offsetTop;
                this.bgContainerDom.addEventListener('mousemove', this.mousemoveDom);

            },
            //鼠标抬起
            moveEventUp() {
                this.bgContainerDom.removeEventListener('mousemove', this.mousemoveDom);

            },
            //鼠标移动
            mousemoveDom(event) {
                event.preventDefault();
                this.bg2Dom.style.left = event.clientX - this.disx + 'px';
                this.bg2Dom.style.top = event.clientY - this.disy + 'px';
            }
        }
    }
</script>

<style scoped>
    .CSImage-container {
        position: relative;
        overflow: hidden;
    }

    .CSImage-container .bg1 {
        width: 100%;
        height: 100%;
    }

    .CSImage-container .bgContainer {
        position: fixed;
        z-index: 1;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);

    }

    .CSImage-container .bgContainer .btnsd {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        font-size: 24px;
        font-weight: bold;
        line-height: 50px;
        background-color: rgba(0,0,0,0.1);
        text-align: center;
        right: 0;
        top: 0;
        z-index: 2;
        position: absolute;
        cursor: pointer;
        color: white;
    }

    .CSImage-container .bgContainer .bg2 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        cursor: pointer;
    }

    .enlargeBtnClass {
        width: 100%;
        height: 40px;
        background-color: rgba(23, 34, 12, 0.4);
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 24px;
        line-height: 40px;
        cursor: pointer;
        text-align: right;
        color: red;
        padding-right: 10px;
        transform: translateY(40px);
        transition: all 1s;
    }
    .CSImage-container:hover .enlargeBtnClass{
        transform: translateY(0);
    }
</style>