<template>
  <div id="component">
    <div class="filter-panel">
      <input
          type="text"
          placeholder="搜索台账名称/备注"
          class="cs-input"
          style="margin: 0; margin-bottom: 15px"
          v-model="queryAccountData.search"
      >
      <CSSelect style="margin-left: 20px">
        <select style="width: 145px" name="" id="" v-model="queryAccountData.type">
          <option value="">全部类型</option>
          <option
              v-for="type in judgmentAuthority(standingBookType)"
              :key="type.id"
              :value="type.id"
          >{{ type.name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect>
        <select style="width: 145px" name="" id="" v-model="queryAccountData.principalUser">
          <option value="">全部负责人</option>
          <option
              v-for="staff in staffList"
              :key="staff.id"
              :value="staff.id"
          > {{ staff.name }}
          </option>
        </select>
      </CSSelect>
      <button
          type="button"
          class="btn btn-primary"
          style="margin-left: 10px"
          @click="queryAccount()"
      >
        查询
      </button>
    </div>

    <div class="result-panel">

      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                type="button"
                class="btn btn-primary sticky-right"
                @click="addAccount()"
            >
              <svg
                  class="icon"
                  aria-hidden="true"
              >
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              台账
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>创建时间</th>
            <th>台账名称</th>
            <th>类型</th>
            <th>状态</th>
            <th>供应商</th>
            <th>数量/面积</th>
            <th>位置</th>
            <th>图片</th>
            <th>巡检点</th>
            <th>备注</th>
            <th>负责人</th>
            <th>创建人</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr
              v-for="account in accountList"
              :key="account.id"
          >
            <td class="center date-td">
              {{ account.createTime }}
            </td>
            <td class="center">
              {{ account.name }}
            </td>
            <td class="center">
              {{ account.type == 1 ? "绿植" : account.type == 2 ? "设备" : "其他" }}
            </td>
            <td class="center">
              {{ account.state == 1 ? "正常" : account.state == 2 ? "损坏" : "停用" }}
            </td>
            <td class="center">
              <p>
                {{ account.supplier }}
              </p>
              <p>
                {{ account.supplierUser }}
              </p>
              <p>
                {{ account.supplierPhone }}
              </p>
            </td>
            <td class="center">
              {{ account.quantityArea }}
            </td>
            <td class="center">
              {{ account.position }}
            </td>
            <td class="center">
                <span
                    class="allow-click"
                    @click="lookImages(account.picture)"
                >
                  查看
                </span>
            </td>
            <td class="center">
                <span
                    class="allow-click"
                    @click="toInspectionPoint(account.pointIds)"
                    v-if="JSON.parse(account.pointIds).length != 0"
                >
                  {{ JSON.parse(account.pointIds).length }}
                </span>
              <span v-else>
                  -
                </span>
            </td>
            <td class="center">
                <span
                    v-if="account.comment != undefined"
                    class="allow-click"
                    @click="lookRemarks(account.comment)"
                >
                  查看
                </span>
              <span v-else>-</span>
            </td>
            <td class="center">
              {{ account.principalUserName }}
            </td>
            <td class="center">
              {{ account.createUserName }}
            </td>
            <td class="center">
              <div
                  class="btn-group"
                  v-if="checkedChildTab === 1"
              >
                <button
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  操作
                </button>
                <ul
                    class="dropdown-menu"
                    style="min-width: 76px; width: 76px;"
                >
                  <li
                      @click="editAccount(account)"
                  >
                    <a
                        style="width: 100%;"
                    >修改</a>
                  </li>
                  <li
                      @click="deleteAccount(account.id)"
                  >
                    <a
                        style="width: 100%;"
                    >删除</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <div>
      </div>
      <Pagination
          name="Pagination"
          component="Pagination"
      ></Pagination>
    </div>
    <!-- 添加台账对话框 -->
    <CSDialog
        :dialogVisible="addAccountVisibel"
        dialogWidth="965px"
        @click="InspectionPointInfo.treePanelVisible = false"
        @onClose="addAccountVisibel = false; InspectionPointInfo.treePanelVisible = false"
        @onConfirm="addEditAccount" dialog-confirm-btn-text="保存"
        :dialogTitle="dialogTitle" :is-submitting="isSubmitting"
    >
      <div slot="dialog-content" class="add-account-body">
        <div class="notice_box">
          <div class="notice_title">台账名称</div>
          <input
              class="notice_name"
              type="text"
              v-model="standingBook.name"
              placeholder="限10个字"
              maxlength="10"
          />
        </div>
        <div class="notice_box">
          <div class="notice_title">类型</div>
          <CSSelect
              height="40px"
              iWidth="36px"
          >
            <select style="width: 215px; font-size: 24px; padding-left: 6px;color: #999999" v-model="standingBook.type">
              <option value="">请选择</option>
              <option
                  style="color: #000"
                  v-for="type in judgmentAuthority(standingBookType)"
                  :key="type.id"
                  :value="type.id"
              >
                {{ type.name }}
              </option>
            </select>
          </CSSelect>
        </div>
        <div class="notice_box">
          <div class="notice_title">状态</div>
          <CSSelect
              height="40px"
              iWidth="36px"
          >
            <select style="width: 215px; font-size: 24px; padding-left: 6px;color: #999999"
                    v-model="standingBook.state">
              <option value="">请选择</option>
              <option
                  style="color: #000"
                  v-for="state in judgmentAuthority(standingBookState)"
                  :key="state.id"
                  :value="state.id"
              >
                {{ state.name }}
              </option>
            </select>
          </CSSelect>
        </div>
        <div class="notice_box">
          <div class="notice_title">供应商</div>
          <input
              class="notice_name"
              type="text"
              placeholder="限30个字"
              v-model="standingBook.supplier"
              maxlength="30"
          />
        </div>
        <div class="notice_box">
          <div class="notice_title">供应商对接人</div>
          <input
              class="notice_name"
              type="text"
              placeholder="限10个字"
              v-model="standingBook.supplierUser"
              maxlength="10"
          />
        </div>
        <div class="notice_box">
          <div class="notice_title">对接人电话</div>
          <input
              class="notice_name"
              type="text"
              placeholder="限11个数字"
              v-model="standingBook.supplierPhone"
              maxlength="11"
          />
        </div>
        <div class="box_fontsize notice_box" style="margin-bottom: 27px">
          <div class="notice_title">数量/面积</div>
          <span class="radio-right">
            <input
                type="radio"
                v-model="standingBook.unitType"
                :value="1"
            />
            <span class="inputSpace">数量(个)</span>
          </span>
          <span class="radio-right">
            <input
                type="radio"
                v-model="standingBook.unitType"
                :value="2"
            />
            <span class="inputSpace">面积(m²)</span>
          </span>
          <div>
            <div class="notice_title"></div>
            <input
                class="notice_name"
                type="text"
                placeholder="限10个数字"
                v-model="standingBook.quantityArea"
                maxlength="10"
            />
          </div>
        </div>
        <div class="notice_box">
          <div class="notice_title">位置</div>
          <div style="display: inline-block;">
            <ChooseLocation @changeLocation="changeLocation" :defaultLocationName="standingBook.position"/>
          </div>
        </div>
        <div class="notice_box">
          <div class="notice_title">负责人</div>
          <CSSelect
              height="40px"
              iWidth="36px"
          >
            <select style="width: 215px; font-size: 24px; padding-left: 6px;color: #999999"
                    v-model="standingBook.principalUser">
              <option value="">请选择</option>
              <option
                  style="color: #000"
                  v-for="staff in staffList"
                  :key="staff.id"
                  :value="staff.id"
              >
                {{ staff.name }}
              </option>
            </select>
          </CSSelect>
        </div>
        <div>
          <div class="notice_title">图片</div>
          <div style="display: inline-block; margin-bottom: 20px;">
            <div v-for="(item, index) in standingBook.picture"
                 :key="index"
                 class="photo-view"
                 style="width: 80px; height: 80px"
            >
              <img
                  :src="item"
                  style="width: 80px; height: 80px"
              />
              <i class="icon-close" @click="deletePhotos(index)"></i>
            </div>
            <div
                class="upload-photo"
                style="width: 80px; height: 80px"
                @click="triggerChoosePhoto"
                v-if="standingBook.picture.length <= 4 "
            >
              <div class="text-center">
                <img
                    src="@/assets/upload.png"
                    alt=""
                    style="margin: 5px auto 5px"
                />
                <p style="color: #999">上传照片</p>
              </div>
            </div>

            <input
                type="file"
                class="file"
                accept="images/*"
                id="uploadStaffPhoto"
                hidden
                @change="chooseStaffPhoto($event)"
            />
          </div>
        </div>
        <div class="notice_box">
          <div class="notice_title">巡检点</div>
          <div
              style="display: inline-block; vertical-align: middle"
              @click.stop="InspectionPointInfo.treePanelVisible = !InspectionPointInfo.treePanelVisible"
          >
            <div class="tree-wrap" style="--height: 40px; --icon-max-width: 36px">
              <CSSelect
                  height="40px"
                  style="width: 226px"
                  iWidth="36px"
              >
                <div
                    v-if="pointNames.length > 0"
                    style="width: 200px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                >
                  <span
                      v-for="(roomName, roomIndex) in pointNames"
                      :key="roomIndex"
                      style="font-size: 24px; margin-left: 10px"
                  >
                    {{ roomName }}
                  </span>
                </div>
                <div
                    v-else
                    style="font-size: 24px; color: #999; margin-left: 10px"
                >
                  请选择
                </div>
              </CSSelect>
              <TreePanel
                  v-show="InspectionPointInfo.treePanelVisible"
                  @loadMore="getInspectionPoint()"
                  width="420px"
              >
                <CSTree
                    :tree="InspectionPoint"
                    @change="InspectionPointInfo.onChangeRooms"
                >
                </CSTree>
              </TreePanel>
            </div>
          </div>
        </div>
        <div class="notice_box">
          <div class="notice_title" style="vertical-align: top">
            备注
          </div>
          <div
              style="width: 540px; display: inline-block"
          >
          <textarea
              type="number"
              style="
                width: 718px;
                background-color: #f0f0f0;
                border: 1px solid #f0f0f0;
                border-radius: 4px;
                height: 198px;
                font-size: 24px;
                resize: none;
                padding-left: 10px
              "
              placeholder="限50个字"
              v-model="standingBook.comment"
              maxlength="50"
          ></textarea>
          </div>
        </div>
      </div>
    </CSDialog>
    <!-- 查看备注对话框 -->
    <CSDialog
        :dialogVisible="lookRemarksVisibel"
        dialogWidth="580px" dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="备注"
        @onClose="lookRemarksVisibel = false"
        :dialogShowConfirmBtn="false"
    >
      <div
          slot="dialog-content"
          style="text-align: center; font-size: 24px; padding: 30px; color: #000"
      >
        {{ standingBook.comment == "" ? "无备注" : standingBook.comment }}
      </div>
    </CSDialog>
    <ViewImageModal name="viewImageModal" componentName="ViewImageModal">
    </ViewImageModal>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import ViewImageModal from "@/components/ViewImageModal";
import TreePanel from "@/components/common/TreePanel";
import CSTree from "@/components/common/CSTree";
import Pagination from "@/components/Pagination";
import ChooseLocation from "@/components/ChooseLocation";

import {DEPARTMENT_TYPES, JOB_LEVEL} from "@/constant";

import {
  queryAccountUrl,
  addAccountUrl,
  deleteAccountUrl,
  editAccountUtl,
  queryDepartmentStaffUrl,
  getuserInfo,
  queryInspectionPointUrl
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

const MEETING_ROOM_TYPE = {
  PUBLIC: 1,
  PRIVATE: 2,
};
export default {
  name: "Ledger",
  data() {
    return {
      isSubmitting: false,
      MEETING_ROOM_TYPE,
      // 部门类型
      DEPARTMENT_TYPES,
      // 职级
      JOB_LEVEL,
      filterHeight: 0,
      // 用户信息
      userInfo: this.$vc.getCurrentStaffInfo(),
      checkedChildTab: 1,
      addAccountVisibel: false,
      lookRemarksVisibel: false,
      loadId: null,
      // 类型
      standingBookType: {
        [DEPARTMENT_TYPES.PROCTER_GAMBLE]: {
          1: {id: 1, name: "绿植"},
          3: {id: 3, name: "其它"}
        },
        [DEPARTMENT_TYPES.ENGINEERING]: {
          2: {id: 2, name: "设备"},
          3: {id: 3, name: "其它"},
        },
      },
      standingBookState: {
        [DEPARTMENT_TYPES.PROCTER_GAMBLE]: {
          1: {id: 1, name: "正常"},
          2: {id: 2, name: "损坏"},
        },
        [DEPARTMENT_TYPES.ENGINEERING]: {
          1: {id: 1, name: "正常"},
          2: {id: 2, name: "损坏"},
          3: {id: 3, name: "停用"}
        }
      },
      // 列表数据
      accountList: [],
      pointNames: [],
      // 巡检点组件数据
      InspectionPointInfo: {
        treePanelVisible: false,
        onChangeRooms: (checkBoxInfo) => {
          const {key, source} = checkBoxInfo,
              checkbox = source[key];
          if (!checkbox?.children) {
            const index = this.standingBook.pointIds.indexOf(checkbox.id);
            if (index > -1) {
              checkbox.checked = false;
              this.pointNames.splice(index, 1);
              this.standingBook.pointIds.splice(index, 1);
            } else {
              checkbox.checked = true;
              this.standingBook.pointIds.push(checkbox.id);
              this.pointNames.push(checkbox.name);
            }
          } else {
            this.changeChildrenCheckState(
                checkbox,
                {checkedIds: this.standingBook.pointIds, roomNames},
                !checkbox.checked
            );
          }
        },
      },
      // 添加台账里的数据
      standingBook: {
        regionCode: "",          //园区code 必填
        name: "",                //必填
        type: "",                //1.绿植2.设备3.其他    必填
        state: "",               //1.正常2.损坏3.停用   必填
        supplier: "",            //供应商   必填
        supplierUser: "",        //供应商对接人   必填
        supplierPhone: "",       //供应商电话   必填
        quantityArea: "",        //数量/面积   必填
        unitType: "",            //1.数量个2.面积m   必填
        locationId: "",            //位置/范围   必填
        principalUser: "",       //负责人id   必填
        picture: [],             //图片   必填
        position: '',
        pointIds: [],            //巡检点数组   必填
        comment: "",             //备注
      },
      // 查询台账数据
      queryAccountData: {
        regionCode: "",          //区域code 必填
        search: "",              //选填
        type: "",                //选填
        principalUser: "",       //负责人id 选填
        createUser: "",          //创建人id 选填
        pageNo: "",              //为空查询全部
        pageSize: 10,            //默认10
      },
      // 员工列表
      staffList: "",
      // 巡检点
      InspectionPoint: [],
      // 巡检点页数
      InspectionPointpageNum: 0,
      // 对话框标题
      dialogTitle: "",
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  components: {
    CSTable,
    CSSelect,
    CSDialog,
    ViewImageModal,
    TreePanel,
    CSTree,
    Pagination,
    ChooseLocation,
  },
  created() {
    window.addEventListener("keydown", this.queryAccountDown);
    // 获取台账列表
    this.getAccountList();
    this.$vc.on(this.$route.path, "pagination", "page_event", (currentPage) => {
      this.pageParams.pageNo = currentPage;
      this.getAccountList(currentPage);
    })
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryAccountDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.queryAccountDown);
  },
  mounted() {
    const departmentCode = this.userInfo.departmentCode;
    // 获取员工列表
    this.getStaffList(departmentCode);
    // 获取巡检点数组
    this.getInspectionPoint();
    this.queryAccount();
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
  },
  methods: {
    queryAccountDown(e) {
      if (e.keyCode == 13) {
        this.queryAccount();
      }
    },
    changeLocation(location) {
      this.standingBook.locationId = location.id;
      this.standingBook.position = `${location.buildingName || ''}${location.floor ? location.floor + '层' : ''}${location.specificLocation}`;
    },
    // 获取台账列表
    getAccountList(pageNo = this.pageParams.pageNo, pageSize = 10) {
      let JOB_LEVEL = this.$vc.getCurrentStaffInfo().jobLevel;
      let dutyType = Number(this.$vc.getCurrentStaffInfo().dutyType);
      if ([1001, 1002].includes(dutyType)) {
        this.$fly
            .post(queryAccountUrl, {
              regionCode: this.$vc.getCurrentRegion().code,
              pageNo,
              pageSize,
              type: ""
            }).then(res => {
          if (res.code !== 0) {
            return
          }
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize,
            })
          }
          this.accountList = res.data.datas;
        })
      } else if (Object.values(this.DEPARTMENT_TYPES).includes(dutyType) && JOB_LEVEL == this.JOB_LEVEL.SUPERVISOR) {
        let type = this.judgeType();
        this.$fly
            .post(queryAccountUrl, {
              regionCode: this.$vc.getCurrentRegion().code,
              pageNo,
              pageSize,
              type
            }).then(res => {
          if (res.code !== 0) {
            return;
          }
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize
            })
          }
          this.accountList = res.data.datas;
        })
      }
    },
    // 判断类型
    judgeType() {
      let dutyType = Number(this.$vc.getCurrentStaffInfo().dutyType);
      let type = "";
      if (this.queryAccountData.type == 3) {
        return type = 3
      } else if ([1001, 1002].includes(dutyType)) {
        return type = this.queryAccountData.type;
      }
      switch (dutyType) {
        case this.DEPARTMENT_TYPES.PROCTER_GAMBLE:
          type = 1;
          break;
        case this.DEPARTMENT_TYPES.ENGINEERING:
          type = 2;
          break;
        default:
          break;
      }
      return type;
    },
    // 获取员工列表
    getStaffList(departmentCode) {
      this.$fly
          .post(queryDepartmentStaffUrl, {
            regionCode: this.$vc.getCurrentRegion().code || "",
            departmentCode,
            jobLevel: '',
            isOnTheJob: true,
          }).then(res => {
        if (res.code !== 0) {
          return
        }
        this.staffList = res.data;
      })
    },
    // 查询台账
    queryAccount() {
      this.$fly
          .post(queryAccountUrl, {
            ...this.queryAccountData,
            regionCode: this.$vc.getCurrentRegion().code
            //type: this.judgeType()
          }).then(res => {
        if (res.code !== 0) {
          return
        }
        this.accountList = res.data.datas;
      })
    },
    // 添加修改台账
    addEditAccount() {
      if (!this.standingBookValidate()) {
        this.$vc.toast(this.$vc.validate.errInfo);
        return;
      }
      const params = {
        ...this.standingBook,
        regionCode: this.$vc.getCurrentRegion().code || "",
        picture: JSON.stringify(this.standingBook.picture),
        pointIds: JSON.stringify(this.standingBook.pointIds)
      };
      let url = '';
      if (this.loadId) {
        url = editAccountUtl;
        params.id = this.loadId;
      } else {
        url = addAccountUrl;
      }
      this.isSubmitting = true;
      this.$fly
          .post(url, params
          )
          .then(res => {
            if (res.code !== 0) {
              return
            }
            this.getAccountList();
            this.addAccountVisibel = false;
          })
          .finally(() => this.isSubmitting = false)
    },
    // 添加
    addAccount() {
      this.loadId = null;
      this.InspectionPoint.forEach(item => {
        if (this.standingBook.pointIds.includes(item.id)) {
          item.checked = false;
        }
      })
      this.pointNames = [];
      this.dialogTitle = "添加台账";
      this.standingBook = {
        regionCode: "",
        name: "",
        type: "",
        state: "",
        supplier: "",
        supplierUser: "",
        supplierPhone: "",
        quantityArea: "",
        unitType: "",
        locationId: "",
        position: '',
        principalUser: "",
        picture: [],
        pointIds: [],
        comment: "",
      };
      this.addAccountVisibel = true;
    },
    // 修改
    editAccount(account) {
      if (!Array.isArray(account.picture)) {
        account.picture = JSON.parse(account.picture);
      }
      this.pointNames = [];
      this.dialogTitle = "修改台账";
      let pointIds = JSON.parse(account.pointIds);
      this.standingBook = {
        regionCode: "",
        name: account.name,
        type: account.type,
        state: account.state,
        supplier: account.supplier,
        supplierUser: account.supplierUser,
        supplierPhone: account.supplierPhone,
        quantityArea: account.quantityArea,
        unitType: account.unitType,
        locationId: account.locationId,
        position: account.position,
        principalUser: account.principalUser,
        picture: account.picture,
        pointIds,
        comment: account.comment,
      };
      this.InspectionPoint.forEach(item => {
        item.checked = false;
        if (this.standingBook.pointIds.includes(item.id)) {
          item.checked = true;
          this.pointNames.push(item.name);
        }
      });
      this.loadId = account.id;
      this.addAccountVisibel = true;
    },
    // 删除
    deleteAccount(id) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定删除吗?",
        onConfirm: () => {
          this.$fly
              .post(deleteAccountUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
                id,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$vc.toast("删除成功");
                this.$CSDialog.instance.closeDialog();
                this.getAccountList();
              })
        }
      })
    },
    // 获取巡检点数组
    getInspectionPoint() {
      let params = {
        regionId: this.$vc.getCurrentStaffInfo().regionCode,
        buildingId: "",
        orgId: "",
        keyword: "",
        operatorId: this.$vc.getCurrentStaffInfo().id,
        pageNum: ++this.InspectionPointpageNum,
        pageSize: 50,
      }
      this.$fly
          .post(queryInspectionPointUrl, params)
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.InspectionPoint.push(...res.data.datas.map(point => {
              point.checked = false;
              return point;
            }));
          })
    },
    //  调起选择文件
    triggerChoosePhoto() {
      $("#uploadStaffPhoto").trigger("click");
    },
    // 上传图片
    async uploadPhoto(base64) {
      return new Promise((reslove, reject) => {
        this.$vc.http.post(
            "uploadImg",
            "upload",
            {
              img: base64,
            },
            {
              headres: {
                "Content-Type": "application/json",
              },
            },
            (resStr, res) => {
              reslove(res.body.fileSaveName);
            },
            (errText, err) => {
              reject(errText);
            }
        );
      });
    },
    // 选择文件后处理
    chooseStaffPhoto(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2MB!");
          return false;
        }
        var reader = new FileReader(); //新建FileReader对象
        reader.readAsDataURL(file); //读取为base64
        reader.onloadend = async (e) => {
          const photo = await this.uploadPhoto(reader.result).catch(
              (err) => {
                console.error("上传照片失败");
                return "";
              }
          );
          this.standingBook.picture.push(photo);
          event.target.value = "";
        };
      }
    },
    deletePhotos(index) {
      this.standingBook.picture.splice(index, 1);
    },
    // 判断类型权限
    judgmentAuthority: function (judgmentObj) {
      let udutyType = Number(this.userInfo.dutyType);
      let jobLevel = this.userInfo.jobLevel;
      let types = {};
      if ([1001, 1002, 1012].includes(udutyType)) {
        for (let firstKey in judgmentObj) {
          for (let secondKey in judgmentObj[firstKey]) {
            if (types[secondKey]) {
              continue
            }
            types[secondKey] = judgmentObj[firstKey][secondKey];
          }
        }
        return types;
      } else if (jobLevel == 1) {
        switch (udutyType) {
          case this.DEPARTMENT_TYPES.PROCTER_GAMBLE:
            return judgmentObj[this.DEPARTMENT_TYPES.PROCTER_GAMBLE];
          case this.DEPARTMENT_TYPES.ENGINEERING:
            return judgmentObj[this.DEPARTMENT_TYPES.ENGINEERING];
          default:
            break;
        }
      }
    },
    // 查看图片
    lookImages(photos) {

      if (!Array.isArray(photos)) {
        this.$vc.emit(this.$route.path, "viewImageModal", "view", JSON.parse(photos));
      } else {
        this.$vc.emit(this.$route.path, "viewImageModal", "view", photos);
      }
    },
    // 去巡检点二级页面
    toInspectionPoint(pointIds) {
      if (typeof pointIds == "string") {
        this.$router.push({path: "point", query: {pointIds}});
      } else {
        this.$router.push({path: "point", query: {pointIds: JSON.stringify(pointIds)}});
      }
    },
    // 查看备注
    lookRemarks(comment) {
      this.standingBook.comment = comment;
      this.lookRemarksVisibel = true;
    },
    // 校验表单
    standingBookValidate() {
      return this.$vc.validate.validate(
          {
            standingBook: this.standingBook,
          },
          {
            "standingBook.name": [
              {
                limit: "required",
                param: "",
                errInfo: "台账名称不能为空",
              }
            ],
            "standingBook.type": [
              {
                limit: "required",
                param: "",
                errInfo: "类型不能为空",
              }
            ],
            "standingBook.state": [
              {
                limit: "required",
                param: "",
                errInfo: "状态不能为空",
              }
            ],
            "standingBook.supplier": [
              {
                limit: "required",
                param: "",
                errInfo: "供应商不能为空",
              }
            ],
            "standingBook.supplierUser": [
              {
                limit: "required",
                param: "",
                errInfo: "供应商对接人不能为空",
              }
            ],
            "standingBook.supplierPhone": [
              {
                limit: "required",
                param: "",
                errInfo: "供应商电话不能为空",
              },
              {
                limit: "phone",
                param: "",
                errInfo: "请输入正确的电话",
              },
            ],
            "standingBook.unitType": [
              {
                limit: "required",
                param: "",
                errInfo: "数量/面积单选框不能为空",
              },
            ],
            "standingBook.quantityArea": [
              {
                limit: "required",
                param: "",
                errInfo: "数量/面积不能为空",
              },
              {
                limit: "num",
                param: "",
                errInfo: "数量/面积只能填数字"
              }
            ],
            "standingBook.locationId": [
              {
                limit: "required",
                param: "",
                errInfo: "位置不能为空",
              },
            ],
            "standingBook.picture": [
              {
                limit: "required",
                param: "",
                errInfo: "图片不能为空",
              }
            ],
            "standingBook.pointIds": [
              {
                limit: "required",
                param: "",
                errInfo: "巡检点不能为空",
              }
            ]
          })
    }
  }
}
</script>

<style lang="stylus" scoped>
p
  margin-bottom 0

.notice_box
  margin-bottom 23px

.cancel_
  width 80px
  height 40px
  background #FF9F00
  box-shadow 0px 5px 10px 0px rgba(255, 159, 0, 0.3)
  border-radius 6px
  color #fff
  font-size 24px
  border none
  margin-right 60px

.preview_
  width 80px
  height 40px
  background #1ab394
  box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.3)
  border-radius 6px
  color #fff
  font-size 24px
  border none

.notice_name
  width 400px
  height 38px
  padding-left 10px
  font-size 24px
  border 1px solid #999
  border-radius 4px
  vertical-align middle

.notice_title
  display inline-block
  width 144px
  font-size 24px
  color #000
  text-align right
  margin-right 40px
  vertical-align middle

.box_fontsize
  font-size 24px

.inputSpace
  margin-left 10px
  color #000

.radio-right
  margin-right 30px

.add-account-body
  padding 26px 30px 30px 30px

.el-input__suffix
  border-left 1px solid #000

.tree-wrap
  max-width 100%
  position relative

  .cs-input
    width 215px

input::-webkit-input-placeholder
  color: #999;

input::-moz-placeholder
  color: #999;

input::-moz-placeholder
  color: #999;

input::-ms-input-placeholder
  color: #999;
</style>
