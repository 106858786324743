<template>
  <section class="table-wrap">
    <div
        class="table-head"
        ref="tableThead"
        :style="{
        '--thead-position-top':
          typeof theadTop === 'string' ? theadTop : theadTop + 'px',
      }"
    >
      <div class="table-header">
        <slot name="header"></slot>
      </div>
      <table>
        <thead>
        <slot name="thead">
          <tr>
            <th>列表1</th>
            <th>列表2</th>
            <th>列表3</th>
            <th>列表4</th>
            <th>列表5</th>
            <th>列表6</th>
            <th>列表7</th>
            <th>列表8</th>
            <th>列表9</th>
          </tr>
        </slot>
        </thead>
      </table>
    </div>
    <div class="table-body" ref="tableBody">
      <table>
        <tbody>
        <slot name="tbody">
          <tr v-if="isLoading">
            <td :colspan="tdLength" class="wrapper-loading">
              <div style="text-align: center">加载中
              </div>
              <div class="loading loading1"></div>
              <div class="loading loading2"></div>
              <div class="loading loading3"></div>
              <div class="loading loading4"></div>
              <div class="loading loading5"></div>
            </td>
          </tr>
          <tr v-else>
            <td :colspan="tdLength">
              <div style="text-align: center">暂无数据</div>
            </td>
          </tr>
        </slot>
        </tbody>
      </table>
    </div>
    <div class="scroll-bar" ref="scrollBar">
      <div style="height: 1px"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CSTable",
  props: {
    theadTop: {
      type: [String, Number],
      default: 0,
    },
    customClass: String,
  },
  data() {
    return {
      tdLength: 0,
      loadingS:false,
      isLoading: true,
      watchTimer: null,
      mounTimer: null,
    };
  },
  mounted() {
    this.initTable();
    this.$vc.on("startLoading", () => {
      this.isLoading = true;
    });
    this.$vc.on("clearLoading", () => {
      clearTimeout(this.mounTimer);
    });
    this.$vc.on("loading", (item) => {
      if(item == true){
        this.isLoading = true;
      }
      clearTimeout(this.mounTimer);
      this.mounTimer = setTimeout(() => {
        if(item == true){
          return
        }
        let loadingS = this.loadingS;
        this.isLoading = loadingS;
      }, 1000);
    });
  },
  updated() {
    this.initTable();
  },
  activated() {
    this.initTable();
  },
  methods: {
    initTable() {
      const {scrollBar, tableBody, tableThead} = this.$refs;
      const _this = this;

      function bindScroll(e) {
        const {scrollLeft} = e.target,
            els = [scrollBar, tableBody, tableThead];
        els.splice(els.indexOf(e.target), 1);
        els.forEach((el) => {
          el.scrollTo(scrollLeft, 0);
        });
      }

      function initTable() {
        let tableWidth = 0,
            scrollInnerBarWidth =
                tableBody.getElementsByTagName("table")[0].clientWidth;
        const tdEls = tableBody
                .getElementsByTagName("table")[0]
                .getElementsByTagName("tr")[0]
                .getElementsByTagName("td"),
            thEls = tableThead
                .getElementsByTagName("table")[0]
                .getElementsByTagName("tr")[0]
                .getElementsByTagName("th");

        _this.tdLength = thEls.length;
        if (thEls.length === tdEls.length) {
          for (let i = 0; i < tdEls.length; i++) {
            thEls[i].style.minWidth = tdEls[i].offsetWidth + "px";
            tableWidth += tdEls[i].offsetWidth;
          }
          tableThead.getElementsByClassName("table-header")[0].style.minWidth =
              tableWidth + "px";
        } else {
          scrollInnerBarWidth =
              tableThead.getElementsByTagName("table")[0].clientWidth;
        }
        const headerPanel =
            tableThead.getElementsByClassName("table-header-panel")[0];
        if (headerPanel) {
          headerPanel.style.minWidth = scrollInnerBarWidth + "px";
        }
        scrollBar.getElementsByTagName("div")[0].style.width =
            scrollInnerBarWidth + "px";
        if (scrollInnerBarWidth > scrollBar.clientWidth) {
          scrollBar.addEventListener("scroll", bindScroll);
          tableThead.addEventListener("scroll", bindScroll);
          tableBody.addEventListener("scroll", bindScroll);
        }
      }

      initTable();
      const CUSTOM_IFRAME_NAME = "table-iframe";
      let tableIframe = document.getElementById(CUSTOM_IFRAME_NAME);
      if (!tableIframe) {
        tableIframe = document.createElement("iframe");
        tableIframe.setAttribute("id", CUSTOM_IFRAME_NAME);
        tableIframe.style.width = "100%";
        tableIframe.style.height = "100%";
        tableIframe.style.position = "absolute";
        tableIframe.style.opacity = 0;
        tableIframe.style.top = "0";
        tableIframe.style.left = "0";
        tableIframe.style.zIndex = "-10";

        document.getElementById("page-wrapper").appendChild(tableIframe);
      }
      tableIframe.contentWindow.addEventListener("resize", () => {
        scrollBar.removeEventListener("scroll", bindScroll);
        tableThead.removeEventListener("scroll", bindScroll);
        tableBody.removeEventListener("scroll", bindScroll);
        initTable();
      });
    },
    updateLoading(res) {
      this.isLoading = false
    }
  },
};
</script>

<style lang="stylus" scoped>
.table-wrap {
  width: 100%;
  margin-bottom: 20px;
  // min-width 1140px
}

.table-head, .table-body {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.table-head {
  position: sticky;
  top: var(--thead-position-top, 0px);
  z-index: 99;
  box-shadow: 0 1px 1px 0 #dbdbdb;
  background: var(--table-bg, #fff);

  span {
    display: inline-block;
  }

  .table-header {
    &-panel {
      border-bottom: 1px solid #ddd;
      padding: var(--table-header-padding, 20px);
      margin-bottom: 0;

      .sticky-right {
        display: inline-block;
        position: sticky;
        right: 20px;
        z-index: 99;
      }
    }
  }
}

.table-body {
  padding-bottom: 140px;
  margin-bottom: -140px;

  table td {
    table {
      background: rgb(230, 228, 228);

      tbody tr {
        td {
          background: rgb(230, 228, 228);
          border-bottom: 1px solid #ccc;
        }

        &:last-of-type td {
          border: none;
          padding-bottom: 0;
        }
      }

      thead {
        tr {
          th, td {
            background: rgb(230, 228, 228);
            border-bottom: 1px solid #ccc;
          }

          &:first-of-type {
            th, td {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

.scroll-bar {
  position: sticky;
  bottom: 0;
  z-index: 99;
  width: 100%;
  overflow-x: auto;
}

table {
  min-width: 100%;
  background: var(--table-bg, #fff);

  tbody {
    tr:not(:last-of-type) {
      td {
        border-bottom: 1px solid #ddd;
      }
    }
  }

  th, td {
    min-width: 120px;
    padding: 15px 10px;
    text-align: center;

    &.date-td {
      min-width: 140px;
    }

    &.full-date-td {
      min-width: 160px;
    }
  }
}

.loading {
  animation: identifier 2s linear 0s infinite;
  width: 1px;
  background-color: black;
  height: 15px;
  margin-left: 5px;
}

.loading2 {
  animation: identifier 2s linear 0.1s infinite;
}

.loading3 {
  animation: identifier 2s linear 0.2s infinite;
}

.loading4 {
  animation: identifier 2s linear 0.3s infinite;
}

.loading5 {
  animation: identifier 2s linear 0.4s infinite;
}

.wrapper-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes identifier {
  0% {
    height: 15px;
  }
  25% {
    height: 0px;
  }
  50% {
    height: 15px;
  }
  75% {
    height: 0px;
  }
  100% {
    height: 15px;
  }
}
</style>
