<template>
    <div class="choose-location">
        <CSSelect height="40px" i-width="36px" v-if="showInput">
            <input
                    type="text"
                    :placeholder="corres.outPlaceholderTxt"
                    class="readonly-input"
                    readonly
                    @click.stop="clcikStop()"
                    v-model="locationName"
            />
        </CSSelect>
        <div height="40px" class="inputSelect" v-else>
            <input
                    type="text"
                    :placeholder="corres.outPlaceholderTxt"
                    class="readonly-input"
                    @click.stop="clcikStop()"
                    v-model="locationName"
                    @input="getLocationList(locationName, $event)"
            />
        </div>
        <div
                class="location-panel"
                v-if="identify == 5 && locationName.length == 11 && locationPanelVisible"
                @click.stop
        >
            <div v-if="identify === 1 && showInput" class="location-search">
                <input
                        type="text"
                        :placeholder="corres.placeholderTxt"
                        v-model="locationKeyWord"
                />
                <button
                        class="btn btn-primary font"
                        @click="getLocationList(locationKeyWord, $event)"
                >
                    查询
                </button>
            </div>
            <div v-else-if="showInput" class="location-search">
                <input
                        style="width: 100%"
                        type="text"
                        :placeholder="corres.placeholderTxt"
                        v-model="locationKeyWord"
                        @input="getLocationList(null, $event)"
                />
            </div>

            <div class="tip" v-if="identify !== 5">
                <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-menua-zu92"></use>
                </svg>
                {{ corres.tipTxt }}
            </div>

            <div class="location-result" v-if="locationList.length > 0">
                <p
                        v-for="(item, index) in locationList"
                        :key="index"
                        @click="changeLocation(item)"
                >
                    <label v-if="identify === 2">
                        <input
                                style="
                vertical-align: middle;
                margin-bottom: 2.5px;
                margin-right: 5px;
              "
                                type="checkbox"
                                v-model="missionList"
                                :value="item.id"
                        />
                        <span
                        >{{ item.name }} - {{ item.model }} - {{ item.companyName }}</span
                        >
                    </label>
                    <span v-else-if="identify === 3"
                    >{{ item.companyName }} - {{ item.dockingPeople }} -
            {{ item.dockingPeoplePhone }}</span
                    >
                    <span v-else-if="identify === 5"
                    >{{ item.name || item.userName || "未知" }} -
            {{ item.gender === 1 ? "男" : item.gender === 2 ? "女" : "未知" }} -
            {{ item.phone || "未知" }}</span
                    >
                    <span v-else
                    >{{ item.buildingName || ""
            }}{{ item.floor ? item.floor + "层" : ""
            }}{{ item.specificLocation }}</span
                    >
                </p>
            </div>
            <div class="location-result" v-else-if="identify === 5">
                <span>暂无此人</span>
            </div>
        </div>
        <div
                class="location-panel"
                v-else-if="locationPanelVisible && identify !== 5"
                @click.stop
        >
            <div v-if="identify === 1 && showInput" class="location-search">
                <input
                        type="text"
                        :placeholder="corres.placeholderTxt"
                        v-model="locationKeyWord"
                />
                <button
                        class="btn btn-primary font"
                        @click="getLocationList(locationKeyWord, $event)"
                >
                    查询
                </button>
            </div>
            <div v-else-if="showInput" class="location-search">
                <input
                        style="width: 100%"
                        type="text"
                        :placeholder="corres.placeholderTxt"
                        v-model="locationKeyWord"
                        @input="getLocationList(null, $event)"
                />
            </div>

            <div class="tip" v-if="identify !== 5">
                <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-menua-zu92"></use>
                </svg>
                {{ corres.tipTxt }}
            </div>

            <div class="location-result" v-if="locationList.length > 0">
                <p
                        v-for="(item, index) in locationList"
                        :key="index"
                        @click="changeLocation(item)"
                >
                    <label v-if="identify === 2">
                        <input
                                style="
                vertical-align: middle;
                margin-bottom: 2.5px;
                margin-right: 5px;
              "
                                type="checkbox"
                                v-model="missionList"
                                :value="item.id"
                        />
                        <span
                        >{{ item.name }} - {{ item.model }} - {{ item.companyName }}</span
                        >
                    </label>
                    <span v-else-if="identify === 3"
                    >{{ item.companyName }} - {{ item.dockingPeople }} -
            {{ item.dockingPeoplePhone }}</span
                    >
                    <span v-else-if="identify === 5"
                    >{{ item.name || "未知" }} -
            {{ item.gender === 1 ? "男" : item.gender === 2 ? "女" : "未知" }} -
            {{ item.phone || "未知" }}</span
                    >
                    <span v-else
                    >{{ item.buildingName || ""
            }}{{ item.floor ? item.floor + "层" : ""
            }}{{ item.specificLocation }}</span
                    >
                </p>
            </div>
            <div class="location-result" v-else-if="identify === 5">
                <span>暂无此人</span>
            </div>
        </div>
    </div>
</template>

<script>
    import CSSelect from "@/components/common/CSSelect";
    import {
        warehouseItemUrl,
        querySimilarLocationUrl,
        warehouseSupplierDetailUrl,
        queryUserByPhone,
        queryBuildingUserUrl
    } from "@/requestUrl";

    export default {
        name: "ChooseLocation",
        components: {
            CSSelect,
        },
        props: {
            mission: String,
            placeholder: String,
            defaultLocationName: String,
            showInput: {
                type: Boolean,
                default: true,
            },
            showType:{
                type:Boolean,
                default:false
            }
        },
        data() {
            return {
                isBangBang: {
                    type: Boolean,
                    default: false
                },
                identify: 1,
                locationList: [],
                locationPanelVisible: false,
                timer: null,
                locationName: "",
                locationKeyWord: "",
                missionList: [],
                corres: {
                    placeholderTxt: "",
                    tipTxt: "",
                    outPlaceholderTxt: "",
                },
            };
        },
        created() {
            window.addEventListener('keydown', this.keyClear)
            if (this.defaultLocationName) {
                this.locationName = this.defaultLocationName;
            }
            switch (this.mission) {
                case "itemManage":
                    this.identify = 3;
                    this.corres.outPlaceholderTxt = "请选择";
                    this.corres.placeholderTxt = "搜索公司名称/对接人/对接人手机号";
                    this.corres.tipTxt = "如果没有可用的供应商，请联系行政部负责人添加。";
                    break;
                case "mission":
                    this.identify = 2;
                    this.corres.outPlaceholderTxt = "请选择";
                    this.corres.placeholderTxt = "搜索物品名称/品牌型号/供应商名称";
                    break;
                case "camera":
                    this.identify = 4;
                    this.corres.outPlaceholderTxt = "请选择监控";
                    this.corres.placeholderTxt = "搜索监控编号/位置";
                    this.corres.tipTxt = "此列表只显示已开启人流统计检测的监控";
                    break;
                case "deputy":
                    this.identify = 5;
                    this.corres.outPlaceholderTxt = "请输入手机号（自动检索）";
                    this.corres.placeholderTxt = "请输入手机号（自动检索）";
                    break;
                default:
                    this.identify = 1;
                    this.corres.outPlaceholderTxt = "请选择";
                    this.corres.placeholderTxt = "搜索位置";
                    this.corres.tipTxt = "如果没有可用的位置，请联系运营部负责人添加。";
                    if (!this.$vc.isEmpty(this.placeholder)) {
                        this.corres.outPlaceholderTxt = this.placeholder;
                    }
            }
        },
        updated() {
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.keyClear);
        },
        deactivated() {
            window.removeEventListener('keydown', this.keyClear);
        },
        mounted() {

            document.getElementsByTagName("body")[0].addEventListener("click", () => {
                this.locationPanelVisible = false;
            });
            document
                .getElementsByClassName("cs-dialog")[0]
                .addEventListener("click", () => {
                    this.locationPanelVisible = false;
                });

            this.getLocationList();
            if (this.mission == "itemManage") {
                this.$vc.on("itemManage", (item) => {
                    this.locationPanelVisible = false;
                });
            }
        },
        methods: {
            clcikStop() {
                this.locationPanelVisible = !this.locationPanelVisible;
                if (this.mission == "") {
                    this.$vc.emit("itemManage", 1);
                }
            },
            /**/
            keyClear(e) {
                if (e.keyCode == 8 && this.mission == 'deputy') {
                    if (this.locationName.length >= 13) {
                        this.locationName = "";
                    }
                }
            },
            /**
             * 获取位置列表
             * @param {String} specificLocation 相似位置
             * */
            getLocationList(specificLocation = "") {
                // console.log(specificLocation);
                switch (this.identify) {
                    case 2:
                        this.$fly
                            .post(warehouseItemUrl, {
                                regionCode: this.$vc.getCurrentRegion().communityId,
                                state: 1,
                                search: specificLocation,
                            })
                            .then((res) => {
                                this.locationList = res.data.datas;
                            });
                        break;
                    case 3:
                        this.$fly
                            .post(warehouseSupplierDetailUrl, {
                                regionCode: this.$vc.getCurrentRegion().communityId,
                                search: specificLocation,
                                isEnable: true,
                            })
                            .then((res) => {
                                this.locationList = res.data.datas || res.data;
                            });
                        break;
                    case 5:
                        if (this.isBangBang) {
                            this.$fly
                                .get(queryBuildingUserUrl, {
                                    // phone: this.locationKeyWord,
                                    phone: specificLocation,
                                })
                                .then((res) => {
                                    this.locationList = res.data.datas || res.data;
                                    if (this.showType) {
                                        if (specificLocation.length === 11 && this.locationList.length <1) {
                                            this.$emit("changeLocation", {phone: specificLocation,notFont:true});
                                        }
                                    }
                                });
                        } else {
                            this.$fly
                                .get(queryUserByPhone, {
                                    // phone: this.locationKeyWord,
                                    phone: specificLocation,
                                })
                                .then((res) => {
                                    this.locationList = res.data.datas || res.data;
                                });
                        }

                        break;
                    default:
                        setTimeout(() => {
                            this.$fly
                                .post(querySimilarLocationUrl, {
                                    regionCode: this.$vc.getCurrentRegion().code,
                                    specificLocation: this.locationKeyWord,
                                })
                                .then((res) => {
                                    if (res.code !== 0) {
                                        return;
                                    }
                                    this.locationList = res.data;
                                    console.log(res.data);
                                });
                        }, 100);
                }
            },
            /**
             * 搜索位置
             * @param {Object} e 事件对象
             * */
            searchLocation(e) {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    this.getLocationList(e.target.value);
                }, 500);
            },
            /**
             * 修改选中的位置
             * @param {Object} location 位置
             * */
            changeLocation(location) {
                console.log('location');
                console.log(location);
                if (this.identify === 2) {
                    return;
                }
                if (this.identify === 3) {
                    this.locationPanelVisible = false;
                    this.locationName = `${location.companyName} - ${location.dockingPeople} - ${location.dockingPeoplePhone}`;
                    this.$emit("addSupplier", location);
					return;
                }
                if (this.identify === 5) {
                    this.locationPanelVisible = false;
                    if (this.showType){
                        this.locationName = location.phone || '未知'
                    }else {
                        this.locationName = `${location.name || location.userName || "未知"} - ${
                            location.gender === 1 ? "男" : location.gender === 2 ? "女" : "未知"
                        } - ${location.phone || "未知"}`;
                    }
                    this.$emit("changeLocation", location);
                    return;
                }
                this.locationPanelVisible = false;
                this.locationName = `${location.buildingName || ""}${
                    location.floor ? location.floor + "层" : ""
                }${location.specificLocation}`;
                this.$emit("changeLocation", location);
            },
        },
        watch: {
            missionList: function () {
                this.locationName = `已选${this.missionList.length}个物品`;
                this.$emit("missionItemList", this.missionList);
            },
        },
    };
</script>

<style scoped lang="stylus">
    .choose-location {
        position: relative;
        line-height: 1;
        font-size: 20px;

        .readonly-input {
            width: var(--readonly-input-width, 364px);
            height: var(--readonly-input-height, 40px);
            border-radius: 4px;
            // border 1px solid #999
            padding: 0 10px;
            border: unset;
            font-size: 24px;
            outline: none;
        }

        .location-panel {
            position: absolute;
            background: #fff;
            z-index: 9;
            top: 50px;
            left: 0;
            border-radius: 10px;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
            width: var(--location-panel-width, 400px);
            padding: 10px;

            .location-search {
                width: 100%;

                input[type='text'] {
                    border-radius: 4px;
                    border: 1px solid #999;
                    padding: 0 10px;
                    vertical-align: middle;
                    width: 300px;
                    height: 40px;
                    box-sizing: border-box;
                }

                button {
                    vertical-align: middle;
                    float: right;
                    height: 40px;
                    padding: 0;
                    width: 60px;
                    line-height: 40px;
                    box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
                }
            }

            .tip {
                color: #999;
                font-size: 20px;
                margin-top: 10px;
            }

            .location-result {
                max-height: 400px;
                overflow-y: auto;
                padding: 10px 0;

                p {
                    cursor: pointer;
                    margin-bottom: 0;
                    height: 48px;
                    line-height: 46px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &:not(:last-of-type) {
                        border-bottom: 1px solid #f0f0f0;
                    }
                }
            }
        }
    }

    .font {
        font-size: 20px;
    }

    .inputSelect {
        border: 1px solid rgb(151, 151, 151);
        border-radius: 4px;
    }
</style>
