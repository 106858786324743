<template>
    <div>
        <CSDialog
            :dialogTitle="title"
            :dialogVisible="carouselVisible"
            dialogCancelBtnText="关闭"
            dialog-header-class="alert-bg"
            @onClose="carouselVisible = false"
            :dialogShowConfirmBtn="false"
            :style="{'z-index': zIndex}"
        >
            <div slot="dialog-content" style="padding: 30px 0">
                <el-carousel height="400px" :autoplay="false" :arrow="photos.length > 1 ? 'hover' : 'never'"
                 :indicator-position="photos.length > 1 ? 'outside' : 'none'"
                >
                    <el-carousel-item
                        v-for="(item, n) in photos"
                        :key="n"
                    >
<!--                        <CSImage :srcImg="item" style="min-width: 300px;max-height: 400px;display: block;margin: 0 a">-->

<!--                        </CSImage>-->
                        <img
                            :src="item"
                            style="
                                min-width: 300px;
                                max-height: 400px;
                                display: block;
                                margin: 0 auto;
                            "
                        />
                    </el-carousel-item>
                </el-carousel>
                <div></div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
import CSDialog from "@/components/common/CSDialog";
import CSImage from "@/components/common/CSImage";
export default {
    name: "ViewImageModal",
    components: {
        CSDialog,
        CSImage
    },
    data() {
        return {
            photos: [],
            title: '查看图片',
            carouselVisible: false,
            zIndex: 2020,
        };
    },

    mounted() {
        this.$vc.on(this.$route.path, "viewImageModal", "view", this.listenerModal);
        this.$vc.on(this.$route.path, "viewImageModal", "close", () => {
            this.carouselVisible = false;
        });
    },
    methods: {
        splitPhotosString(photos) {
            if (typeof photos == "string") {
                return photos.split(",");
            }
            if (Array.isArray(photos)) {
                return photos;
            }
            if (photos instanceof Object) {
                if (photos.zIndex) {
                    this.zIndex = photos.zIndex;
                }
                this.title = photos.title;
                return photos.photos;
            }
        },
        listenerModal(photosStr) {
            console.log(this, '组件对象');
            if (!photosStr) {
                this.$vc.message("图片地址错误");
                return;
            }
            console.log(photosStr);
            this.photos = this.splitPhotosString(photosStr);
            this.carouselVisible = true;
        },
    },
};
</script>

<style lang="stylus" scoped>
.btn-close
    height 40px
    width 80px
    background-color #FF9F00
    font-size 24px
    color #fff
    text-align center
    line-height 0px
    box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
    border-radius: 6px;
.box
    border none
</style>
