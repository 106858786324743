<template>
    <div>
        <div class="tree-item" v-for="(item, key) in tree" :key="item.id">
            <div :class="['tree-item-name', item[itemsName] && item[itemsName].length > 0 ? 'has-icon' : '']">
                <div
                    v-if="item[itemsName] && item[itemsName].length > 0"
                    @click="changeUnfold(item.id)"
                    :class="[
                        'glyphicon',
                        'glyphicon-triangle-right',
                        {
                            rotate: unfold[item.id],
                        },
                    ]"
                ></div>
                <input
                    v-if="showCheckbox && (item.showCheckbox == null || item.showCheckbox)"
                    type="checkbox"
                    :checked="item.checked"
                    @change="changeChecked(tree, item.allowCheck != null && !item.allowCheck, key)"
                    :class="[
                        {
                            notAllChecked:
                                !item.checked && hasChildChecked[item.id],
                            disabled: item.allowCheck != null && !item.allowCheck
                        },
                    ]"
                />
                <span class="itemName" @click="clickChecked(tree, item.allowCheck != null && !item.allowCheck, key)">{{ item[textName] }}</span>
            </div>
            <template v-if="item[itemsName] && item[itemsName].length > 0">
                <div
                    :class="['tree-item-child', { hasCheckbox: showCheckbox }]"
                    v-show="unfold[item.id]"
                >
                    <CSTree
                        :itemsName="itemsName"
                        :tree="item[itemsName]"
                        :showCheckbox="showCheckbox"
                        :idName="idName"
                        @change="onChange"
                        :textName="textName"
                    ></CSTree>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "CSTree",
    components: {},
    props: {
        tree: Array,  // 数据源
        itemsName: {  // 下级循环的数据对应的key名
            default: "children",
            type: String,
        },
        enableClickPick: {
          default: false,
          type: Boolean,
        },
        idName: { //
            default: "id",
            type: String,
        },
        showCheckbox: {   // 是否显示选择框
            type: Boolean,
            default: true,
        },
        textName: {  // 展示的文案的key名
            default: "name",
            type: String,
        },
    },
    data() {
        return {
            unfold: {},
            hasChildChecked: {},
        };
    },
    watch: {
        tree: {
            deep: true,
            handler(val) {
                val.forEach((item, index) => {
                    this.$set(this.hasChildChecked, item.id, this.changeHasChildrenCheckedStatus(item)[0] && item.allowCheck);
                    this.$set(this.tree[index], 'checked', this.changeHasChildrenCheckedStatus(item)[1])
                })
            },
        },
    },
    methods: {
        /**
         * 更改下级的选中状态, 用于全选，全不选的操作
         * @param {Object} item 组件中下级的数据
         * @return {Array<Boolean>}
         * 返回数组中下标为0的元素是存储下级中是否有选中的元素，如果有一个则为true
         * 返回数组中下标为1的元素是表示下级是否全部选中
         * */
        changeHasChildrenCheckedStatus(item) {
            let hasChildChecked = false,
                checkedArr = [],
                children = item[this.itemsName];
            if (children && children.length > 0) {
                children.forEach(child => {
                    if (child.checked && child.allowCheck) {
                        hasChildChecked = true;
                    }
                    checkedArr.push(child.checked /*|| !child.allowCheck*/);
                })
                return [hasChildChecked, !checkedArr.includes(false)];
            }
            return [false, item.checked];
        },
        /**
         * 用于向父级传参
         * @param {Object}
         * {
         *     key, 选中的元素在数据中的下标
         *     source 传入到组件中的数据
         * }
         * */
        onChange({ key, source }) {
            this.$emit("change", {
                key,
                source,
            });
        },
        /**
         * 选中元素
         * @param {Object} source 传入到组件中的数据
         * @param {Boolean} isDisabled 是否是禁止选中
         * @param {Number} key 当前选中的元素的下标
         * */
        changeChecked(source, isDisabled, key) {
            if (isDisabled) {
                return;
            }
            this.$emit("change", {
                key,
                source,
            });
        },
        /**
         * 选中元素
         * @param {Object} source 传入到组件中的数据
         * @param {Boolean} isDisabled 是否是禁止选中
         * @param {Number} key 当前选中的元素的下标
         * */
        clickChecked(source, isDisabled, key) {
            const item = source[key];
            if (item[this.itemsName] && item[this.itemsName].length > 0) {
                this.changeUnfold(item.id);
            }
            if (!this.enableClickPick) {
                return ;
            }
            if (isDisabled) {
                return;
            }
            this.$emit("change", {
                key,
                source,
            });
        },
        /**
         * 更改展开关闭状态
         * @param {Number | String} id 节点的唯一标识
         *  */
        changeUnfold(id) {
            this.$set(this.unfold, id, !this.unfold[id]);
        },
    },
};
</script>


<style lang="stylus" scoped>
.tree-item
    &-child
        padding-left 20px
        &.hasCheckbox
            padding-left 50px
    &-name
        font-size 24px
        white-space nowrap
        cursor pointer
        input
            vertical-align middle
            margin-right 8px
            &[type='checkbox']
                &.disabled
                    &::after
                        position absolute
                        width 20px
                        height 20px
                        top 0
                        background-image url("../../../public/common/static/img/checkbox/disabledChecked.png");
                        background-size 105% 105%;
                        background-position center;
                        display inline-block
                        border-radius 3px
                        border 1px solid #979797
                        cursor no-drop
        span
            vertical-align middle
            //width calc(100% - 50px)
            overflow hidden
            display inline-block
            white-space nowrap
            text-overflow ellipsis
        &.has-icon
          span
            max-width calc(100% - 20px - 8px - 12px - 8px)
    .glyphicon.glyphicon-triangle-right
        font-size 10px
        margin-right 8px
        vertical-align middle
    .glyphicon.glyphicon-triangle-right.rotate
        -webkit-animation arrowRotate 0.1s linear 1
        animation arrowRotate 0.1s linear 1
        transform rotate(90deg)
    @keyframes arrowRotate
        0%
            -webkit-transform rotate(0deg)
        50%
            -webkit-transform rotate(45deg)
        100%
            -webkit-transform rotate(90deg)
    @keyframes arrowRotate
        0%
            -webkit-transform rotate(0deg)
        50%
            -webkit-transform rotate(45deg)
        100%
            -webkit-transform rotate(90deg)
</style>
